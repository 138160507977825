import moment from 'moment';
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import StartIcon from '@mui/icons-material/Start';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { getPrimaryGuestNames, isEmpty } from '../../../utils/Utils';

function CheckinStart () {
    const { checkinKey, checkin, profile, reservation, linkedReservations, depositFolio, roomsRequired, availableRooms, roomsPreAssigned, ccAuthUrl } = useSelector((state) => state.checkin);
    const navigate = useNavigate();
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);

    const arrivalDate = moment(reservation.roomStay?.arrivalDate).format('Do MMM YYYY');
    const departureDate = moment(reservation.roomStay?.departureDate).format('Do MMM YYYY');
    const primaryGuestNames = getPrimaryGuestNames(profile);
    const adults = parseInt(reservation.roomStay?.guestCounts.adults) - 1;
    const children = parseInt(reservation.roomStay?.guestCounts.children);
    const guestCount = `${adults === 1 ? '+1 Adult' : adults > 0 ? `+${adults}` : ''} ${adults > 1 ? 'Adults' : ''} 
                        ${children === 1 ? '+1 Child' : children > 0 ? `+${children}` : ''} ${children > 1 ? 'Children' : ''}`
    
    const rooms = `${roomsRequired} x ${reservation.roomStay?.currentRoomInfo.roomType}`;
    const checkinReady = isReadyForCheckIn(roomsRequired, availableRooms, roomsPreAssigned, reservation, linkedReservations, depositFolio, checkin, ccAuthUrl);

    useEffect(() => {
        if (parseFloat(depositFolio.amount) > 0 && !isEmpty(depositFolio.pay_url)) {
            setOpenDialog(true);
        }
    }, [depositFolio])

    const handleStart = () => {
        navigate(`/checkin/${checkinKey}/profile`);
    }

    const handleDeposit = () => {
        window.location.href = depositFolio.pay_url;
        setOpenDialog(false);
    }

    const logoSize = theme.breakpoints.values.sm === 600 ? 30 : 50;
    const HeaderBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& img': { marginRight: theme.spacing(2) }
    }))

    return (
        <Container maxWidth='sm'>
            <Card sx={{ marginTop: 3 }}>
                <CardContent>
                    <Typography variant='body1' gutterBottom>
                        Your reservation details
                    </Typography>
                    <HeaderBox>
                        <img src={checkin.logo_url} alt='Property logo' width={logoSize} height={logoSize} />
                        <Typography variant='h6'>
                            {checkin.hotel_name}
                        </Typography>
                    </HeaderBox>
                    <List dense>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineOutlinedIcon color='secondary' />
                            </ListItemIcon>
                            <ListItemText primary={ adults === 0 && children === 0 ? 'Guest' : 'Guests'} secondary={`${primaryGuestNames.firstName} ${primaryGuestNames.lastName}  ${guestCount}`} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineOutlinedIcon color='secondary' />
                            </ListItemIcon>
                            <ListItemText primary='Arrival' secondary={arrivalDate} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineOutlinedIcon color='secondary' />
                            </ListItemIcon>
                            <ListItemText primary='Departure' secondary={departureDate} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutlineOutlinedIcon color='secondary' />
                            </ListItemIcon>
                            <ListItemText primary='Room' secondary={rooms} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {parseFloat(depositFolio.amount) > 0 ? <ErrorOutlineOutlinedIcon color='warning' /> : <CheckCircleOutlineOutlinedIcon color='secondary' />}
                            </ListItemIcon>
                            <ListItemText primary='Deposit balance' secondary={`${depositFolio.currency} ${depositFolio.amount}`} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {checkinReady ? <CheckCircleOutlineOutlinedIcon color='success' /> : <WarningAmberOutlinedIcon color='error' />}
                            </ListItemIcon>
                            {checkinReady ? <ListItemText primary='Check-in status' secondary='Ready for check-in' /> : <ListItemText primary='Check-in status' secondary='Not available. Please try again later' />}
                        </ListItem>
                    </List>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleStart} variant='contained' disabled={!checkinReady} color='success' endIcon={<StartIcon />} >Check-in</Button>
                </CardActions>
            </Card>

            <Dialog open={openDialog}>
                <DialogTitle>
                    Outstanding deposit
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} align='left'>
                        <Grid item xs={12}>
                            <DialogContentText>
                                Our records include an outstanding deposit amount of {depositFolio.currency} {depositFolio.amount}.
                            </DialogContentText>
                            <DialogContentText>
                                To proceed with check-in, you can pay the outstanding amount now. You will then be returned here to complete your check-in.
                            </DialogContentText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color='secondary'>Cancel</Button>
                    <Button onClick={handleDeposit} color='success' variant='contained' endIcon={<NavigateNextIcon />}>Pay now</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default CheckinStart;


export function isReadyForCheckIn (roomsRequired, availableRooms, roomsPreAssigned, reservation, linkedReservations, depositFolio, checkin, ccAuthUrl) {
    return (roomsPreAssigned || roomsRequired <= availableRooms.length) && (
        roomsPreAssigned ||
        (roomsRequired === 1 && linkedReservations.length === 0) ||
        (roomsRequired === linkedReservations.length)
    ) && (parseFloat(depositFolio.amount) <= 0) && (
        !isEmpty(ccAuthUrl) || !checkin.authorize_ccard || checkin.ccard_authorized
    ) && reservation.computedReservationStatus === 'DueIn'
}


